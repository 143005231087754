'use client';
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import React, { useEffect, useRef, useState } from "react";
import { Avatar, AvatarFallback, AvatarImage, Badge, Button, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger, SimpleTooltip, cn } from "../..";
export function TextCell(props) {
    const textRef = useRef(null);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const overflowStyle = "text-ellipsis text-nowrap overflow-x-hidden";
    useEffect(() => {
        const checkOverflow = () => {
            if (textRef.current) {
                const isOverflowing = textRef.current.scrollWidth > textRef.current.clientWidth;
                setIsOverflowing(isOverflowing);
            }
        };
        checkOverflow();
        window.addEventListener('resize', checkOverflow);
        return () => {
            window.removeEventListener('resize', checkOverflow);
        };
    }, []);
    return (_jsx("div", { className: "relative", style: { minWidth: props.size }, children: _jsxs("div", { className: "flex items-center gap-2 absolute inset-0", children: [_jsx("div", { className: overflowStyle, ref: textRef, children: isOverflowing ? (_jsx(SimpleTooltip, { tooltip: props.children, children: _jsx("div", { className: overflowStyle, children: props.children }) })) : props.children }), props.icon && _jsx("div", { children: props.icon })] }) }));
}
export function AvatarCell(props) {
    return (_jsxs(Avatar, { className: "h-6 w-6", children: [_jsx(AvatarImage, { src: props.src }), _jsx(AvatarFallback, { children: props.fallback })] }));
}
export function DateCell(props) {
    const ignore = !!props.ignoreAfterYears && new Date(new Date().setFullYear(new Date().getFullYear() + props.ignoreAfterYears)) < props.date;
    const timeString = props.date.toLocaleTimeString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' });
    return (_jsx(TextCell, { size: 140, children: ignore ? 'Never' : timeString }));
}
export function ActionCell(props) {
    return (_jsxs(DropdownMenu, { children: [_jsx(DropdownMenuTrigger, { asChild: true, children: _jsxs(Button, { variant: "ghost", className: cn("flex h-8 w-8 p-0 data-[state=open]:bg-muted", props.invisible && "invisible"), children: [_jsx(DotsHorizontalIcon, { className: "h-4 w-4" }), _jsx("span", { className: "sr-only", children: "Open menu" })] }) }), _jsx(DropdownMenuContent, { align: "end", className: "min-w-[150px] max-w-[300px] stack-scope", children: props.items?.map((item, index) => {
                    if (item === '-') {
                        return _jsx(DropdownMenuSeparator, {}, index);
                    }
                    const menuItemProps = {
                        onClick: item.onClick,
                        className: cn(item.danger ? "text-destructive" : ""),
                        disabled: item.disabled
                    };
                    const menuItem = (_createElement(DropdownMenuItem, { ...menuItemProps, key: index }, item.item));
                    if (item.disabled && item.disabledTooltip) {
                        return (_jsx(SimpleTooltip, { tooltip: item.disabledTooltip, children: React.cloneElement(menuItem, {
                                className: cn(menuItemProps.className, "opacity-50 cursor-not-allowed")
                            }) }, index));
                    }
                    return menuItem;
                }) })] }));
}
export function BadgeCell(props) {
    return (_jsx("div", { className: "flex items-center gap-1 flex-wrap", children: props.badges.map((badge, index) => (_jsx(Badge, { variant: "secondary", children: badge }, index))) }));
}
