import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CircleAlert, Info } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, cn } from "..";
export function SimpleTooltip(props) {
    const iconClassName = cn("w-4 h-4 text-zinc-500", props.inline && "inline");
    const icon = props.type === 'warning' ?
        _jsx(CircleAlert, { className: iconClassName }) :
        props.type === 'info' ?
            _jsx(Info, { className: iconClassName }) :
            null;
    const trigger = (_jsxs(_Fragment, { children: [icon, props.children] }));
    return (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, { asChild: true, children: props.inline ? (_jsx("span", { className: cn(props.className), children: trigger })) : (_jsx("div", { className: cn("flex items-center gap-1", props.className), children: trigger })) }), _jsx(TooltipContent, { children: _jsx("div", { className: "max-w-60 text-center text-wrap whitespace-pre-wrap", children: props.tooltip }) })] }) }));
}
