"use client";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow, } from "@stackframe/stack-ui";
import { GlobalFiltering, flexRender, getCoreRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable, } from "@tanstack/react-table";
import React from "react";
import { DataTablePagination } from "./pagination";
import { DataTableToolbar } from "./toolbar";
export function TableView(props) {
    return (_jsxs("div", { className: "space-y-4", children: [_jsx(DataTableToolbar, { table: props.table, toolbarRender: props.toolbarRender, showDefaultToolbar: props.showDefaultToolbar, defaultColumnFilters: props.defaultColumnFilters, defaultSorting: props.defaultSorting }), _jsx("div", { className: "rounded-md border", children: _jsxs(Table, { children: [_jsx(TableHeader, { children: props.table.getHeaderGroups().map((headerGroup) => (_jsx(TableRow, { children: headerGroup.headers.map((header) => {
                                    return (_jsx(TableHead, { colSpan: header.colSpan, children: header.isPlaceholder
                                            ? null
                                            : flexRender(header.column.columnDef.header, header.getContext()) }, header.id));
                                }) }, headerGroup.id))) }), _jsx(TableBody, { children: props.table.getRowModel().rows.length ? (props.table.getRowModel().rows.map((row) => (_jsx(TableRow, { "data-state": row.getIsSelected() && "selected", onClick: (ev) => {
                                    // only trigger onRowClick if the element is a direct descendant; don't trigger for portals
                                    if (ev.target instanceof Node && ev.currentTarget.contains(ev.target)) {
                                        props.onRowClick?.(row.original);
                                    }
                                }, children: row.getVisibleCells().map((cell) => (_jsx(TableCell, { children: flexRender(cell.column.columnDef.cell, cell.getContext()) }, cell.id))) }, row.id)))) : (_jsx(TableRow, { children: _jsx(TableCell, { colSpan: props.columns.length, className: "h-24 text-center", children: "No results." }) })) })] }) }), _jsx(DataTablePagination, { table: props.table })] }));
}
export function DataTable({ columns, data, toolbarRender, defaultVisibility, defaultColumnFilters, defaultSorting, showDefaultToolbar = true, }) {
    const [sorting, setSorting] = React.useState(defaultSorting);
    const [columnFilters, setColumnFilters] = React.useState(defaultColumnFilters);
    const [pagination, setPagination] = React.useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [globalFilter, setGlobalFilter] = React.useState();
    return _jsx(DataTableBase, { columns: columns, data: data, toolbarRender: toolbarRender, defaultVisibility: defaultVisibility, sorting: sorting, setSorting: setSorting, defaultSorting: defaultSorting, columnFilters: columnFilters, setColumnFilters: setColumnFilters, defaultColumnFilters: defaultColumnFilters, manualPagination: false, manualFiltering: false, pagination: pagination, setPagination: setPagination, globalFilter: globalFilter, setGlobalFilter: setGlobalFilter, showDefaultToolbar: showDefaultToolbar });
}
export function DataTableManualPagination({ columns, data, toolbarRender, defaultVisibility, defaultColumnFilters, defaultSorting, onRowClick, onUpdate, showDefaultToolbar = true, }) {
    const [sorting, setSorting] = React.useState(defaultSorting);
    const [pagination, setPagination] = React.useState({ pageIndex: 0, pageSize: 10 });
    const [cursors, setCursors] = React.useState({});
    const [columnFilters, setColumnFilters] = React.useState(defaultColumnFilters);
    const [globalFilter, setGlobalFilter] = React.useState();
    const [refreshCounter, setRefreshCounter] = React.useState(0);
    React.useEffect(() => {
        onUpdate({
            cursor: cursors[pagination.pageIndex],
            limit: pagination.pageSize,
            sorting,
            columnFilters,
            globalFilters: globalFilter,
        }).then(({ nextCursor }) => {
            setCursors(c => nextCursor ? { ...c, [pagination.pageIndex + 1]: nextCursor } : c);
        }).catch(console.error);
    }, [pagination, sorting, columnFilters, refreshCounter]);
    // Reset to first page when filters change
    React.useEffect(() => {
        setPagination(pagination => ({ ...pagination, pageIndex: 0 }));
        setCursors({});
    }, [columnFilters, sorting, pagination.pageSize]);
    // Refresh the users when the global filter changes. Delay to prevent unnecessary re-renders.
    React.useEffect(() => {
        const timer = setTimeout(() => {
            setRefreshCounter(x => x + 1);
        }, 500);
        return () => clearTimeout(timer);
    }, [globalFilter]);
    return _jsx(DataTableBase, { columns: columns, data: data, toolbarRender: toolbarRender, sorting: sorting, setSorting: setSorting, pagination: pagination, setPagination: setPagination, columnFilters: columnFilters, setColumnFilters: setColumnFilters, rowCount: pagination.pageSize * Object.keys(cursors).length + (cursors[pagination.pageIndex + 1] ? 1 : 0), globalFilter: globalFilter, setGlobalFilter: setGlobalFilter, defaultColumnFilters: defaultColumnFilters, defaultSorting: defaultSorting, defaultVisibility: defaultVisibility, showDefaultToolbar: showDefaultToolbar, onRowClick: onRowClick });
}
function DataTableBase({ columns, data, toolbarRender, defaultVisibility, sorting, setSorting, defaultColumnFilters, defaultSorting, pagination, setPagination, rowCount, columnFilters, setColumnFilters, globalFilter, setGlobalFilter, manualPagination = true, manualFiltering = true, showDefaultToolbar = true, onRowClick, }) {
    const [rowSelection, setRowSelection] = React.useState({});
    const [columnVisibility, setColumnVisibility] = React.useState(defaultVisibility || {});
    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            columnVisibility,
            rowSelection,
            columnFilters,
            pagination,
            globalFilter: globalFilter,
        },
        enableRowSelection: true,
        onGlobalFilterChange: setGlobalFilter,
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        onPaginationChange: setPagination,
        getColumnCanGlobalFilter: (c) => c.columnDef.enableGlobalFilter ?? GlobalFiltering.getDefaultOptions(table).getColumnCanGlobalFilter(c),
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        autoResetAll: false,
        manualPagination,
        manualFiltering,
        rowCount,
    });
    return _jsx(TableView, { table: table, columns: columns, toolbarRender: toolbarRender, showDefaultToolbar: showDefaultToolbar, defaultColumnFilters: defaultColumnFilters, defaultSorting: defaultSorting, onRowClick: onRowClick });
}
